<template>
  <v-app>
    <v-container id="login" class="fill-height justify-center">
      <v-row justify="center">
        <v-col lg="11" sm="8" xl="7">
          <v-card class="elevation-4">
            <v-row>
              <base-authentication-aux></base-authentication-aux>
              <v-col lg="5" class="pr-0">
                <div class="pa-7 pa-sm-12">
                  <v-img src="@/assets/logo-firmaronline-dark.svg" />
                  <h2
                    class="font-weight-bold mt-4 blue-grey--text text--darken-2"
                  >
                    Accede con tu cuenta
                  </h2>
                  <v-btn
                    color="primary"
                    block
                    class="mr-4 mt-4 w-100"
                    submit
                    @click="submit"
                    >Acceder
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Footer from "../../layouts/full-layout/footer/Footer.vue";
export default {
  name: "Login",

  components: {
    Footer
  },

  mounted() {
    if (window.HybridAppHook) this.$router.push({ name: "printer" });
  },

  methods: {
    submit() {
      window.HybridAppHook
        ? this.$auth.signIn("/printer")
        : this.$auth.signIn("/signatures/remote/sent");
    },
    more() {
      window.open("https://firmar.online", "_blank");
    }
  }
};
</script>
